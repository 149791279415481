//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
Vue.use(VueRouter);

//引入路由组件
import HomePage from '@/view/homePage/index'
import CareersPage from '@/view/careersPage/index'
import AboutPage from '@/view/aboutPage/index'
import PrivacyPage from '@/view/privacyPage/index'
import TernsPage from '@/view/termsPage/index'

const router = new VueRouter({
    mode: 'history',
    //配置路由
    routes:[
        {
            name:'home',
            path: '/home',
            component: HomePage, 
        },{
            name:'careers',
            path: '/careers',
            component: CareersPage, 
        },{
            name:'about',
            path: '/about',
            component: AboutPage, 
        },{
            name:'mahremiyet.html',
            path: '/mahremiyet.html',
            component: PrivacyPage, 
        },{
            name:'sartlar.html',
            path: '/sartlar.html',
            component: TernsPage, 
        },{
            path: '*',
            redirect: '/home',
        }
    ]
  })


export default router
