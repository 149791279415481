<template>
  <div style="background-color: #eaf2ff;">
    <!-- 顶部导航栏组件 -->
    <HeaderPage chackKey="home"></HeaderPage>
    <!-- home页内容 -->
    <div class="container">
      <div class="forgames wow slideInLeft">
        <span class="span1">By Gamers,</span><span class="span2">For Gamers</span>
        <span class="span3">Creating the Very Best in Mobile Gaming</span>
        <div class="icon_img top_icon1">
          <img class="" src="../../assets/image/toplogo2.png" alt="">
        </div>
        <div class="icon_img top_icon2">
          <img class="" src="../../assets/image/toplogo3.png" alt="">
        </div>
      </div>
      <div class="games">
        <div class="minGame">
          <div class="gameLogo"><img src="../../assets/image/minGame1.png" alt=""></div>
          <div>
            <h3 style="font-size: 18px;">Solitaire Field</h3>
            <p style="font-size: 15px;">Strategy</p>
          </div>
        </div>
        <div class="minGame minGame2">
          <div class="gameLogo"><img src="../../assets/image/minGame2.png" alt=""></div>
          <div>
            <h3 style="font-size: 18px;">Feed the Cat</h3>
            <p style="font-size: 15px;">Casual</p>
          </div>
        </div>
        <div class="minGame">
          <div class="gameLogo"><img src="../../assets/image/minGame3.png" alt=""></div>
          <div>
            <h3 style="font-size: 18px;">Ripple Delete</h3>
            <p style="font-size: 15px;">Adventure</p>
          </div>
        </div>
      </div>
      <div class="bgImg">
        <img style="" class="" src="../../assets/image/Home 1.png" alt="">
      </div>
      <div class="forgames">
        <span class="span2">Explore</span><span class="span1">Our Games</span><br><br>
        <p class="span3" style="text-align: center;">We are Myte Games, an up and coming studio. Welcome to our world of
          casual games with a difference!</p>
        <!-- <p class="span3" style="text-align: center;">It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.</p> -->
      </div>
      <!-- our games区域 -->
      <div class="showGames" id="game">
        <div class="gameBox wow animate__fadeInDown">
          <div class="blackBg"></div>
          <div class="gameBg"></div>
          <div class="game">
            <div class="gameTextBox">
              <div class="gametext_left wow animate__backInLeft">
                <div class="gametext_top">
                  <img class="gametext_top_img" src="../../assets/image/minGame1.png" alt="">
                  <div>
                    <h3 style="font-size: 18px;color: #00204c;">Solitaire Field</h3>
                    <p style="font-size: 15px;color: #00204c;">Strategy</p>
                  </div>
                </div>
                <p style="width: 300px; font-size: 15px;color: #00204c; margin-top: 30px; display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 5;
                    overflow: hidden;
                    text-overflow: ellipsis;">
                  Combining traditional solitaire gameplay with multiplayer online battles, Solitaire Field, your choice
                  for leisure time! In this game, you can play against players from all over the world and experience
                  the fun of real competition.
                  <br>
                  Based on the classic Solitaire rules, players need to strategically move the cards in the pile so that
                  they are in the required order according to the number and suit of the cards on the deck. All players
                  in each game have exactly the same initial hand, so you'll need to rely on your skill to achieve a
                  higher score.
                </p>
                <div class="gametext_down">
                  <!-- <img style="width: 130px; height: auto;" src="../../assets/image/Appstore.png" alt=""> -->
                  <img style="width: 130px; height: auto;" src="../../assets/image/Google Play.png" alt="">
                </div>
              </div>
              <div class="gametext_right">
                <div></div>
                <img src="../../assets/image/gamePhone1.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="gameBox  wow animate__fadeInDown">
          <div class="blackBg"></div>
          <div class="gameBg gameBg2"></div>
          <div class="game">
            <div class="gameTextBox gameTextBox2">
              <div class="gametext_left wow animate__backInLeft">
                <div class="gametext_top">
                  <img class="gametext_top_img" src="../../assets/image/minGame2.png" alt="">
                  <div>
                    <h3 style="font-size: 18px;color: #00204c;">Feed the Cat</h3>
                    <p style="font-size: 15px;color: #00204c;">Casual</p>
                  </div>
                </div>
                <p style="width: 300px; font-size: 15px;color: #00204c; margin-top: 30px;">
                  Do you want to have a variety of cute pets? Choose Feed the Cat and you'll be right! You can choose
                  six cute pets to form your team and work hard to become a Smash Master.
                </p>
                <div class="gametext_down">
                  <img style="width: 130px; height: auto;" src="../../assets/image/Appstore.png" alt="">
                  <img style="width: 130px; height: auto;" src="../../assets/image/Google Play.png" alt="">
                </div>
              </div>
              <div class="gametext_right">
                <div></div>
                <img src="../../assets/image/gamePhone2.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="gameBox  wow animate__fadeInDown">
          <div class="blackBg"></div>
          <div class="gameBg gameBg3"></div>
          <div class="game">
            <div class="gameTextBox">
              <div class="gametext_left wow animate__backInLeft">
                <div class="gametext_top">
                  <img class="gametext_top_img" src="../../assets/image/minGame3.png" alt="">
                  <div>
                    <h3 style="font-size: 18px;color: #00204c;">Ripple Delete</h3>
                    <p style="font-size: 15px;color: #00204c;">Adventure</p>
                  </div>
                </div>
                <p style="width: 300px; font-size: 15px;color: #00204c; margin-top: 30px;">
                  You will play the role of a dead king, trying to revive his wife and conquer the world, the road is
                  destined to be thorny, are you ready?
                </p>
                <div class="gametext_down">
                  <img style="width: 130px; height: auto;" src="../../assets/image/Appstore.png" alt="">
                  <img style="width: 130px; height: auto;" src="../../assets/image/Google Play.png" alt="">
                </div>
              </div>
              <div class="gametext_right">
                <div></div>
                <img src="../../assets/image/gamePhone3.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- join us 区域 -->
      <div class="join wow animate__wobble">
        <div class="join_text">
          <h2 class="join_tit" style="color: #00204c;">Come Work at Myte Games !</h2>
          <span class="span1">Great People Make</span>
          <span class="span2">Great Games.</span>
          <br>
          <p class="span1">Explore our Available Opportunities</p>
          <button class="join_btn">See Openings</button><br>
          <img class="join_img" style="border-radius: 15px" src="../../assets/image/join.png" alt="">
        </div>
      </div>
      <!-- contact us 区域 -->
      <div class="contact" id="contact">
        <div class="bgBlack"></div>
        <div class="contact_text">
          <p class="p1">About Myte Games</p>
          <p class="p2">Creating Interactive Mobile</p>
          <p class="p2">Games Played Across the Globe</p>
          <button class="contact_btn">Learn More</button><br>
        </div>
        <div class="contact_box">
          <img src="../../assets/image/mascot.png" alt="">
          <div class="contact_right">
            <h3>Get in Touch</h3>
            <p>Please fill out our form and give us your opinion!</p>
            <el-form :model="Form" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item label="First Name" prop="x">
                <el-input v-model="Form.x" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="Last Name" prop="m">
                <el-input v-model="Form.m" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="Email" prop="Email">
                <el-input v-model.number="Form.Email"></el-input>
              </el-form-item>
              <el-form-item label="subject" prop="subject">
                <el-input v-model.number="Form.subject"></el-input>
              </el-form-item>
              <el-form-item label="message" prop="message">
                <el-input v-model.number="Form.message"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="background-color: #7f03c7; border: none;"
                  @click="submitForm('ruleForm')">submit</el-button>
                <el-button @click="resetForm('ruleForm')">reset</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部组件 -->
    <FooterPage></FooterPage>
  </div>
</template>

<script>
import HeaderPage from "@/components/headerPage/index.vue"
import FooterPage from "@/components/footerPage/index.vue"

export default {
  name: "HomePage",
  components: {
    HeaderPage,
    FooterPage,
  },
  data() {
    return {
      Form: {},
      rules: {
        x: [{ required: true, message: 'Last name is a required field', trigger: 'blur' },],
        m: [{ required: true, message: 'First name is a required field', trigger: 'blur' },],
        Email: [{ required: true, message: 'Email is a required field', trigger: 'blur' },],
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let mailto = 'mailto:ozanalp200@gmail.com?body=' + this.Form;
          window.location.href = mailto;
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    toScroll(id) {
      const element = document.getElementById(id);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
        this.drawer = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background-color: #eaf2ff;

  .forgames {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 200px;

    span {
      display: block;
      font-size: 70px;
    }

    .span1 {
      color: #00204c;
    }

    .span2 {
      background-color: #a600f9;
      text-align: center;
      color: #fff;
      padding: 20px;
      border-radius: 70px;
    }

    .span3 {
      font-size: 28px;
      line-height: 70px;
      color: #00204c;
    }

    .icon_img {
      position: absolute;
      top: 0;
    }

    .top_icon1 {
      left: 0;
    }

    .top_icon2 {
      right: 0;
    }
  }

  .games {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 70px 0;
    flex-wrap: wrap;

    .minGame {
      background-color: #fff;
      width: 280px;
      height: 132px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 20px;
      box-shadow: 9px 9px 5px #999;
      margin: 30px auto;

      .gameLogo {
        border: 3px solid #eaf2ff;
        border-radius: 20px;

        img {
          width: 78px;
          height: auto;
          border-radius: 20px;
        }
      }
    }

    .minGame2 {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .bgImg {
    background-color: #eaf2ff;
    position: relative;
    height: 550px;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: -430px;
    }
  }

  .showGames {
    .gameBox {
      position: relative;
      margin: 50px 0 200px;
      ;

      .blackBg {
        position: absolute;
        opacity: .3;
        width: 80%;
        margin: 0 auto;
        height: 100%;
        background-color: #000;
        top: 0;
        left: 10%;
        z-index: 1;
      }

      .gameBg {
        width: 80%;
        margin: 0 10%;
        height: 680px;
        background-image: url("../../assets/image/bgGame1.png");
        position: absolute;
      }

      .gameBg2 {
        background-image: url("../../assets/image/bgGame2.png");
      }

      .gameBg3 {
        background-image: url("../../assets/image/bgGame3.png");
      }

      .game {
        width: 80%;
        margin: 0 auto;
        height: 680px;

        .gameTextBox {
          position: absolute;
          transform: translate(5vw, 20%);
          border-radius: 20px;
          width: 650px;
          height: 472px;
          background-color: #fff;
          box-shadow: 0px 1px 4px #999;
          z-index: 2;
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          .gametext_left {
            display: flex;
            flex-flow: column;

            .gametext_top {
              display: flex;
              align-items: center;

              .gametext_top_img {
                margin-right: 50px;
                border-radius: 20px;
                border: 3px solid #eaf2ff;
              }
            }

            .gametext_down {
              width: 290px;
              display: flex;
              margin-top: 30px;
              justify-content: space-between;
            }
          }

          .gametext_right {
            position: relative;

            div {
              width: 192px;
              height: 380px;
              left: -3px;
              top: -3px;
              position: absolute;
              background-image: url("../../assets/image/Home.png");
              background-size: cover;
            }

            img {
              width: 186px;
              height: 372px;
              border-radius: 30px;
            }
          }
        }

        .gameTextBox2 {
          top: 10vh;
          transform: translate(35vw);
        }
      }
    }
  }

  .join {
    width: 100%;
    height: 900px;
    padding-left: 10%;
    padding-top: 10%;
    background-image: url("../../assets/image/Home_right.png");
    background-repeat: no-repeat;
    background-size: cover;

    // background-attachment: fixed;
    // animation: slide-left 10s linear infinite;
    // color: #00204c;
    // @keyframes slide-left {
    //   0% { background-position: 300px 50%; }
    //   50% { background-position: 0 50%; }
    //   100% { background-position: 300px 50%; }
    // }
    .join_text {
      .join_tit {
        font-size: 24px;
      }

      .span1 {
        font-size: 44px;
      }

      .span2 {
        font-size: 44px;
        background-color: #a600f9;
        border-radius: 30px;
        padding: 0 10px;
        color: #fff;
      }

      .join_btn {
        width: 150px;
        height: 60px;
        margin: 30px auto;
        background-color: #00204c;
        color: #fff;
        border: none;
        border-radius: 30px;
        font-size: 14px;
        font-weight: bolder;
        transition: background-color .3s;
        cursor: pointer;
      }

      .join_btn:hover {
        background-color: #a600f9;
      }

      .join_img {
        width: 635px;
        height: auto;
      }
    }
  }

  .contact {
    width: 100%;
    padding-left: 10%;
    padding-top: 10%;
    // height: 800px;
    background-image: url("../../assets/image/Home 3.png");
    background-position: 0 -100px;
    background-size: auto;
    position: relative;

    .bgBlack {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #a700fa;
      opacity: .7;
    }

    .contact_text {
      position: relative;
      z-index: 2;

      p {
        color: #fff;
        font-weight: bolder;
      }

      .p1 {
        font-size: 24px;
      }

      .p2 {
        font-size: 44px;
      }

      .contact_btn {
        margin-top: 30px;
        width: 140px;
        height: 60px;
        border-radius: 60px;
        border: 3px solid #fff;
        transition: background-color .3s;
        cursor: pointer;
      }

      .contact_btn:hover {
        color: #fff;
        background-color: #a600f9;
      }
    }

    .contact_box {
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 50px;
      height: 410px;

      img {
        width: 322px;
        height: auto;
      }

      .contact_right {
        width: 490px;
        height: 655px;
        background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        color: #00204c;

        h3 {
          font-size: 24px;
        }

        p {
          font-size: 15px;
          line-height: 30px;
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;

    .forgames {
      margin-top: 30px;
      padding-top: 30px;

      span {
        display: block;
        font-size: 40px;
      }

      .span2 {
        margin: 20px 0;
      }

      .span3 {
        font-size: 15px;
        text-align: center;
        line-height: 30px;
      }

      .icon_img {
        display: none;
      }
    }

    .games {
      flex-flow: column;

      .minGame2 {
        position: static;
        transform: none;
      }
    }

    .bgImg {
      display: none;
    }

    .showGames {
      .gameBox {
        width: 100%;

        .blackBg {
          left: 0;
          width: 100%;
          max-height: 200px;
        }

        .game {
          margin: 0;

          .gameTextBox {
            width: 80%;
            height: 500px;
            flex-direction: column;
            transform: translate(13%, 20%);

            .gametext_left {
              margin: 50px 0;

              .gametext_top {
                margin-top: 180px;
              }
            }
          }
        }

        .gameBg {
          margin: 0;
          width: 100%;
          height: 200px;
          background-size: cover;
        }
      }
    }

    .join {
      background-position-x: -230px;
      background-position-y: -30px;
      height: 420px;

      .join_text {
        .join_tit {
          font-size: 16px;
        }

        .span1 {
          font-size: 16px;
        }

        .span2 {
          font-size: 16px;
        }

        .join_img {
          width: 208px;
        }
      }
    }

    .contact {
      .contact_text {
        .p1 {
          font-size: 14px;
        }

        .p2 {
          font-size: 26px;
        }
      }

      .contact_box {
        justify-content: left;
        margin-bottom: 600px;

        .contact_right {
          margin-top: 20px;
          width: 90%;
        }
      }
    }
  }
}
</style>