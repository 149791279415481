<template>
  <div class="box">
    <!-- <img class="box_logo" src="../../assets/image/logo-200x50.png" alt=""> -->
    <div class="container">
      <h1>Privacy Policy</h1>
      <p class="up_date" style="font-size: 14px;"><strong>DATED: 8th April 2024</strong></p>
      <ul class="ul_1">
        <li>
          <p><strong>GENERAL INFORMATION</strong></p>
          <ul class="ul_2">
            <li>This is a privacy policy published by Myte Games. and this document Outlines the information we process and collect to support the features provided by Myte Games. In this Privacy Policy, the terms "Company", "we", "our" or "our" refer to the relevant company responsible for processing your data. In a broad sense, when we refer to personal information, we mean information or opinions about you, or information that can reasonably be identified as you. It includes information or opinions that may or may not be accurate or recorded in hard copy or electronic form. </li>
            <li>This Privacy Policy (" Privacy Policy ") explains how your personal information is collected, why it is collected, and how it is kept secure.We are committed to complying with all applicable global data privacy laws. </li>
          </ul>
        </li>
        <li>
          <p><strong>INFORMATION WE COLLECT</strong></p>
          <ul class="ul_2">
            <li>a.Personal information is defined as any information about you, whether true or not, provided that your identity is apparent or can be reasonably ascertained from that information. When anonymous information is directly or indirectly associated with personal information, the resulting information is also considered personal information. Anonymous information is information that cannot be directly or indirectly identified and cannot reasonably be used to identify an individual. Aggregate information is information about customer groups or categories that does not identify and cannot reasonably be used to identify individual customers.</li>
            <li>b.We collect two basic types of information – Personal Information and anonymous information – and we may use personal and anonymous information to create a third type of information, aggregate information. </li>
            <li>
              c.We collect: 
              <ul class="ul_3">
                <li>contact details such as your first name and surname, email address, residential address and mobile or other phone numbers; </li>
                <li>personal information such as gender, age, age range or your image; account login details, including your username, password and pictures associated with your profile;</li>
                <li>information sent bilaterally or within a small group using email, post or other methods of communication; </li>
                <li>information we obtain from a third party, such as a site or platform provider, about your use of or interest in our services; </li>
                <li>location information including location information provided by a mobile or other device interacting with one of our Websites, or associated with your IP address, where we are permitted by law to process this information; </li>
                <li>activity, technical and device information about your use of our Game, such as the content you view, the time and duration of your visit on our Game, how often you use our services, how you first heard about our Game, your preferences and information about your interaction with the content offered through our Game, your hardware model, device type, other unique device identifiers, operating system version, browser type and IP address; and </li>
                <li>sensitive information such as biometric data, geolocation, drivers licenses and financial information to comply with our legal and regulatory purposes. </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p><strong>PERSONAL DATA COLLECTED VIA TECHNOLGY</strong></p>
          <ul class="ul_2">
            <li>When you interact with our Game, we try to make that experience simple and useful. We and our partners use industry standard identifiers, such as cookies or other similar technologies.</li>
            <li>Our Game may use technologies to enhance your experience. These technologies are small files that are placed on your computer, tablet, mobile phone or other devices when you visit a website. They allow us to record certain pieces of information whenever you visit or interact with the Game. Usage information may include, in part, browser type, operating system, device type, an estimate of your geographic location associated with your IP address, the location of your mobile device, the page served, the time, referring URLs and other information normally transmitted in HTTP requests. This statistical data provides us with information about your use of the services, such as general engagement with a page, the type of content on that page and how long you stay on that page. Usage information is generally non-identifying but if Company associates it with you as a specific and identifiable person, Company will treat it as Personal Information.</li>
            <li>
              The following are examples of these types of technologies:
              <ul class="ul_3">
                <li>APIs. APIs work by allowing different types of software to communicate with each other. They make life a lot easier for programmers and provide a better experience for end users.</li>
                <li>Cookies. These are small text files placed in your device browsers to store your preferences. Most browsers allow you to block and delete cookies. However, if you do that, the service may not work properly.</li>
                <li>Event tagging. Event tagging allows us to track actions that occur on the Game such as application installs and purchase events. By tracking these events we can view analytics, measure ad performance, and build audiences for ad targeting.</li>
                <li>Mobile device identifiers. We may share the advertising identifier associated with your mobile device or tablet (such as the Apple IDFA and Google Advertising ID) with advertisers. It is used to identify your device for internet-based advertising. For instructions for using the advertising choice mechanisms provided by the most popular mobile operating systems, visit the NAI’s Mobile Choices page here.</li>
                <li>Pixel tags/web beacons. A pixel tag (also known as a web beacon) is a piece of code embedded on the Game that collects information about your engagement on that web page. The use of a pixel allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement. We may also include web beacons in e-mails to understand whether messages have been opened, acted on or forwarded.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p><strong>HOW WE COLLECT YOUR INFORMATION</strong></p>
          <ul class="ul_2">
            <li>We collect information in a variety of ways, as set out below. You confirm that any contact information you provide is true and accurate.</li>
            <li>Information you provide to us directly including when you visit our Game, register on the Game, respond to a survey, fill out a form, and in connection with other activities, services, features or resources we make available on our Game. </li>
            <li>In addition to collecting information via our Game, we collect information from you by telephone, video or in correspondence (including email, live chat, mail, text message or social media). All telephone or video calls between you and Company representatives may be recorded for training, quality control and compliance purposes and by continuing with any such call you consent to it being recorded.</li>
            <li>We also collect information about your use of our products and services through a variety of technologies that are present when you visit our Websites, including the Game, or use our applications on third party sites or platforms (whether or not you are logged in or registered) including cookies, flash cookies, pixels, tags and application programming interfaces (“API”). </li>
            <li>Please see the section entitled “Personal Data Collected via Technology” below for further information. Analytics tools are also used by us to collect information, including when you visit our Game or use our applications or services on third party sites or platforms.</li>
            <li>Finally, we also use trusted sources to update or supplement the information you provided, or we have collected, including in order to verify or validate information (for example, postal address, documentation and names).</li>
          </ul>
        </li>
        <li>
          <p><strong>HOW WE USE COLLECTED INFORMATION</strong></p>
          <ul class="ul_2">
            <li>We use the information collected to operate our Game and to provide our products and services, to respond to your questions and to fulfill all applicable regulatory requirements.</li>
            <li>
              In accordance with applicable laws and any elections made available to you, Company may collect and use your information for the following specific purposes:
              <ul class="ul_3">
                <li>To provide and manage the services you request - This includes, for example, processing your registration, setting up and looking after your account, including checks made to guard against fraud.</li>
                <li>To improve customer service and our services - Information you provide helps us respond to your customer service requests more efficiently; we may use feedback you provide to improve our products and services.</li>
                <li>To personalize user experience - We may use information which has been aggregated to understand how our users as a group use the services and resources provided on our Game.</li>
                <li>To contact you about our services including marketing - For example by email, live chat, telephone, text message, mail or social media..</li>
                <li>To comply with our legal and regulatory obligations - We may use information we collect about you for regulatory obligations (including the detection, investigation and prevention of activity that violates our terms and conditions or that may be illegal).</li>
                <li>To offer alternative dispute resolution services - We may provide information we collect about you to our service provider partner for the specific purpose of resolving any disputes that have not been satisfactorily resolved through our internal processes.</li>
                <li>To conduct market research and analysis – to analyse how you interact with our products and services; to monitor and analyse usage and activity trends; and for other research, analytical and statistical purposes.</li>
              </ul>
            </li>
            <li>We do not use automated decision making within the definition of global data protection laws.</li>
            <li>We may partner with a third party service provider to either display advertising on our Game or any application accessible via our Game or to manage our advertising on other websites. Our third party service provider may use technologies such as cookies to gather information about your activities on our Game and other websites in order to provide you advertising based on your browsing activities and interests.</li>
          </ul>
        </li>
        <li>
          <p><strong>RIGHTS</strong></p>
          <ul class="ul_2">
            <li>If we process your personal information for the purpose of direct advertising, you have the right to object at any time to this processing; this also applies to profiling, where appropriate, insofar as it is associated with such direct advertising.</li>
            <li>You may opt-out of receiving commercial messages from us by following the instructions contained in those messages or by contacting Customer Support. In the event of your objection to processing for the purpose of direct advertising, we will no longer process your personal information for these purposes.</li>
            <li>We appreciate that by law and subject to certain conditions, you have a number of rights concerning the personal information we hold about you. These rights include the right to access the personal information we hold about you, the right to object to the processing of your data, the right to update and correct the information we hold about you, and the right to withdraw any consent provided. In some circumstances, you can ask us to delete the personal information we hold about you.</li>
            <li>We will not discriminate against you (e.g., through denying products and services or providing a different level or quality of goods or services) for exercising any of the rights afforded to you.</li>
            <li>If you would like to use an agent authorized in accordance with applicable law to exercise your rights, we may request evidence that you have provided such agent with power of attorney or that the agent otherwise has valid written authority to exercise those rights on your behalf.</li>
          </ul>
        </li>
        <li>
          <p><strong>HOW WE PROTECT YOUR INFORMATION</strong></p>
          <ul class="ul_2">
            <li>We have in place physical, electronic and operational procedures to protect the information that we collect. We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Game. Our security measures are reviewed regularly and updated in keeping up with technological advances.</li>
            <li>We use reasonable means to protect your personal information from misuse, interference, or loss, as well as any unauthorised access, modification or disclosure.</li>
            <li>If we believe that a notifiable data breach has occurred, we will comply with the legislative requirements to notify you of the data breach and any steps taken by Company to contain and investigate the data breach.</li>
          </ul>
        </li>
        <li>
          <p><strong>HOW LONG WILL WE RETAIN YOUR INFORMATION</strong></p>
          <ul class="ul_2">
            <li>We will retain your information for a period of time to reasonably fulfil the purposes specified in this Privacy Policy, unless a longer retention period is required by law.</li>
            <li>
              We will take reasonable steps to destroy or permanently de-identify any Personal Information where:
              <ul class="ul_3">
                <li>we no longer need the Personal Information for any purpose for which the Personal Information was collected or for a related purpose; and</li>
                <li>we are not required by law, a regulator or a court/tribunal order, to retain the Personal Information.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p><strong>SHARING YOUR INFORMATION</strong></p>
          <ul class="ul_2">
            <li>
              We do not sell, trade, or rent your personal information. However, there are circumstances when we share your personal data with the following categories of third parties:
              <ul class="ul_3">
                <li>Affiliates – other companies in the Company;</li>
                <li>Service providers – other companies who provide services to you on our behalf; and</li>
                <li>Third parties that interact with us in connection with the services we perform. </li>
                <li>If data will be shared with a third party, we require them to maintain the same protections over your data that we provide directly to you and to only perform services on our behalf. Simply put, they are prohibited from using your personal information for purposes other than those requested by us or required by law.</li>
              </ul>
            </li>
            <li>
              We only share information with third parties in limited circumstances including the following:
              <ul class="ul_3">
                <li>when you allow us to share your information with third parties;</li>
                <li>when providing you with products and services and notifying you about either important changes or developments to the features and operation of those products and services;</li>
                <li>in response to lawful requests by public authorities, including to meet national security or law enforcement requirements, when ordered to do so by any regulatory body and/or under any legal provision contained in the governing law of a particular jurisdiction;</li>
                <li>when instructing and authorizing the financial institution with which a user’s account is held to disclose any information as may be requested by a regulator in respect of the user’s account;</li>
                <li>to enforce our terms and conditions set out on our Game, to protect our rights and property and the rights and property of our customers and third parties, to detect fraud or other illegal activities, and to comply with law or legal processes; and</li>
                <li>to perform customer due diligence including ID verification.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p><strong>CHANGES</strong></p>
          <ul class="ul_2">
            <li>This Privacy Policy may be updated from time to time to reflect changes in the way we work or the way our work is regulated. We will notify you of material changes and, where required by law, will obtain your consent. Notice may be by the posting of such changes on our Game or by other means in accordance with applicable laws.</li>
            <li>Any changes to the Privacy Policy will become effective when the updated policy is posted on our Game.</li>
            <li>We encourage you to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect.</li>
            <li>This Privacy Policy, in effect as of the last modified date stated below, supersedes and replaces any and all Privacy Policies previously in effect.</li>
          </ul>
        </li>
        <li>
          <p><strong>CONTACTING US</strong></p>
          <ul class="ul_2">
            <li>If you have specific questions regarding your personal information or how we use it, please contact our Data Protection Officer directly by email at ozanalp200@gmail.com</li>
          </ul>
        </li>        
      </ul>
    </div>
    <FooterPage2></FooterPage2>
  </div>
</template>

<script>
import FooterPage2 from "../../components/footerPage2/index"
export default {
  name: 'RESPONSIBLEGAMEPLAYPOLICY',
  components:{
    FooterPage2,
  }
}
</script>

<style lang="scss" scoped>
.box {
  background-color: #eaf2ff;
  width: 100%;
  padding-bottom: .4167rem;
  h1 {
    text-align: center;
    padding: .4167rem;
    color: #00204c;
  }
}

.container {
  width: 80%;
  margin: 0 auto;
  .up_date{
    margin-bottom: .2604rem;
  }
  span{
    font-size: 14px;
    line-height: .1563rem;
  }
  .tit{
    display: block;
    margin: .1042rem 0;
  }
}

.ul_1 {
  margin-top: .2604rem;
  list-style-type: upper-alpha;
  margin-left: -1em;
  line-height: .1823rem;
  font-size: 20px;
  color: #00204c;
      li{
        color: #00204c;
      }
  .ul_2 {
    font-size: 14px;
    list-style-type: lower-alpha;
    text-align: left;
    line-height: .1302rem;
    padding-left: .026rem;
    margin: .0521rem 0;
    color: #00204c;
      li{
        color: #00204c;
      }
    .ul_3{
      font-size: 14px;
      list-style-type: disc;
      text-align: left;
      line-height: .1302rem;
      padding-left: .0521rem;
      margin: .0521rem 0;
      li{
        color: #00204c;
      }
    }
  }
}
.box_logo{
  width: 200px;
  height: auto;
  position: relative;
  left: 2.0313rem;
  top: .5729rem;
}
@media (max-width: 1000px) {
  .box_logo{
    width: 80px;
    height: auto;
    position: relative;
    left: .1042rem;
    top: 30px;
  }
  .container{
    h1{
      font-size: 24px;
    }
    .tit{
      font-size: 0.01em;
      line-height: 30px;
    }
}
  .ul_1 {
    font-size: 10px;
    strong{
      line-height:20px;
    }
    .ul_2{
      line-height: 30px;
      padding-left: .0521rem;
      .ul_3{
        line-height: 30px;
        padding-left: .0521rem;
      }
    }
  }
}

</style>