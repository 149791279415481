<template>
  <div style="background-color: #eaf2ff;">
    <!-- 顶部导航栏组件 -->
    <HeaderPage chackKey="careers"></HeaderPage>
    <!-- Careers页内容 -->
    <div class="container">
      <!-- contact us 区域 -->
      <div class="contact wow animate__fadeInBottomLeft" id="contact">
        <div class="bgBlack"></div>
        <div class="contact_text">
          <p class="p1">Careers</p>
          <p class="p2">Unleash Your Talent</p>
          <p class="p3">Our games are crafted from unique design <br> and top notch technology, just like our team.</p><br>
        </div>
      </div>
      <!-- 紫色背景区域 -->
      <div class="job wow animate__slideInDown">
        <img class="jobBg" src="../../assets/image/Career 2.png" alt="">
        <h1>Job Openings</h1>
        <div class="jobBox">
          <div class="job1 wow animate__pulse">
            <img style="width: 62px; height: 88px;" src="../../assets/image/job1.png" alt="">
            <br>
            <br>
            <strong>Marketing Specialist</strong>
            <p style="margin: 30px 0;">Barcelona, España</p>
            <p>
              Bachelor's degree or above in Marketing or related field.
              <br> 1-2 years of experience in digital marketing.
              <br> Deep understanding of the gaming industry and user demographics.
            </p>
            <button class="job_btn" @click="()=>{this.dialogFormVisible=true}">Apply</button>
          </div>
          <div class="job2 wow animate__pulse">
            <img style="width: 80px; height: 80px;" src="../../assets/image/job2.png" alt="">
            <br>
            <br>
            <strong>Game Designer</strong>
            <p style="margin: 30px 0;">Barcelona, España</p>
            <p>
              Creative and passionate about game design.
              <br>1-3 years of experience in game design.
              <br>Good communication skills, able to collaborate closely with development team.
            </p>
            <button class="job_btn" @click="()=>{this.dialogFormVisible=true}">Apply</button>
          </div>
          <div class="job3 wow animate__pulse">
            <img style="width: 80px; height: 80px;" src="../../assets/image/job3.png" alt="">
            <br>
            <br>
            <strong>Game Development Engineer</strong>
            <p style="margin: 30px 0;">Barcelona, España</p>
            <p>
              Bachelor's degree or above in Computer Science or related field.
              <br>2-5 years of experience in game development.
              <br>Good teamwork and communication skills.
            </p>
            <button class="job_btn" @click="()=>{this.dialogFormVisible=true}">Apply</button>
          </div>
          <div class="job4 wow animate__pulse">
            <img style="width: 53px; height: 95px;" src="../../assets/image/job4.png" alt="">
            <br>
            <br>
            <strong>Art Designer</strong>
            <p style="margin: 30px 0;">Barcelona, España</p>
            <p>
              Proficiency in design software.
              <br> 2-4 years of experience in game art design.
              <br> Strong teamwork and communication skills.
            </p>
            <button class="job_btn" @click="()=>{this.dialogFormVisible=true}">Apply</button>
          </div>
        </div>
      </div> 
      <div class="tit">
        <h2>Didn’t Find the Position You’re Looking for? <br> Send Us Your CV</h2>
        <button class="tit_btn" @click="()=>{this.dialogFormVisible=true}">Apply Now</button>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="" :visible.sync="dialogFormVisible">
      <el-form :model="Form" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="First Name" prop="x">
          <el-input v-model="Form.x" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Last Name" prop="m">
          <el-input v-model="Form.m" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="Email">
          <el-input v-model.number="Form.Email"></el-input>
        </el-form-item>
        <el-form-item label="subject" prop="subject">
          <el-input v-model.number="Form.subject"></el-input>
        </el-form-item>
        <el-form-item label="message" prop="message">
          <el-input v-model.number="Form.message"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="background-color: #7f03c7; border: none;" @click="submitForm('ruleForm')">submit</el-button>
          <el-button @click="resetForm('ruleForm')">reset</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
    </div>
    </el-dialog>
    <!-- 底部组件 -->
    <FooterPage></FooterPage>
  </div>
</template>

<script>
import HeaderPage from "@/components/headerPage/index.vue"
import FooterPage from "@/components/footerPage/index.vue"

export default {
    name:"CareersPage",
    components:{
      HeaderPage,
      FooterPage,
    },
    data(){
      return{
        dialogFormVisible:false,
        Form:{},
        rules: {
          x: [{ required: true, message: 'Last name is a required field', trigger: 'blur' },],
          m: [{ required: true, message: 'First name is a required field', trigger: 'blur' },],
          Email: [{ required: true, message: 'Email is a required field', trigger: 'blur' },],
          }
      }
    },
  mounted(){
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
  },
    methods:{
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let mailto = 'mailto:ozanalp200@gmail.com?body=' + this.Form;
            window.location.href = mailto;
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    }
}
</script>

<style lang="scss" scoped>
.container{
  background-color: #eaf2ff;
  .contact{
    width: 100%;
    height: 800px;
    padding-left: 10%;
    padding-top: 10%;
    // height: 800px;
    background-image: url("../../assets/image/Career 1.png");
    background-position: 0 -100px;
    background-size: auto;
    position: relative;
    .bgBlack{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #19395c;
      opacity: .7;
    }
    .contact_text{
      position: relative;
      z-index: 2;
      p{
        color: #fff;
        font-weight: bolder;
      }
      .p1{
        font-size: 18px;
      }
      .p2{
        font-size: 70px;
        margin: 30px 0;
      }
      .p3{
        font-size: 28px;
      }
      .contact_btn{
        margin-top: 30px;
        width: 140px;
        height: 60px;
        border-radius: 60px;
        border: 3px solid #fff;
        transition: background-color .3s;
        cursor: pointer;
      }
      .contact_btn:hover{
        color: #fff;
        background-color: #a600f9;
      }
    }
    .contact_box{
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 50px;
      height: 410px;
      img{
        width: 322px;
        height: auto;
      }
      .contact_right{
        width: 490px;
        height: 655px;
        background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        color: #00204c;
        h3{
          font-size: 24px;
        }
        p{
          font-size: 15px;
          line-height: 30px;
          margin-bottom: 50px;
        }
      }
    }
  }
  .job{
    position: relative;
    top: -200px;
    width: 76%;
    margin: 0 auto;
    background-color: #a600f9;
    h1{
      display: block;
      width: 100%;
      text-align: center;
      padding-top: 150px;
      color: #fff;
      font-size: 44px;
      position: relative;
      z-index: 9;
    }
    .jobBg{
      position: absolute;
      width: 50%;
      margin: 0 auto;
      height: auto;
      left: 50%;
      transform: translate(-50%);
      bottom: 0;
    }
    .jobBox{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
      position: relative;
      top: 180px;
      z-index: 9;
      div{
        width: 390px;
        height: 465px;
        border-radius: 20px;
        padding: 30px;
        margin: 30px 150px;
        background-color: #fff;
        strong{
          font-size: 18px;
        }
        p{
          font-size: 15px;
          color: #00204c;
        }
        .job_btn{
          background-color: #00204c;
          border: 3px solid #00204c;
          color: #fff;
          border-radius: 60px;
          width: 150px;
          height: 60px;
          margin: 30px auto;
          transition: background-color .3s;
          cursor: pointer;
        }
        .job_btn:hover{
          background-color: #fff;
          color: #00204c;
        }
      }
    }
  }
  .tit{
    text-align: center;
    margin-top: 10%;
    h2{
      font-size: 44px;
      color: #00204c;
    }
    .tit_btn{
      background-color: #a600f9;
      border: none;
      color: #fff;
      border-radius: 60px;
      width: 150px;
      height: 60px;
      margin: 30px auto;
      transition: background-color .3s;
    }
    .tit_btn:hover{
      background-color: #00204c;
    }
  }
}
@media (max-width: 1815px) {
  .container{
    .job{
      .jobBg{
        top: 5%;
      }
    }
  }
}
@media (max-width: 768px) {
  .container{
    .contact{
      width: 100%;
      height: 350px;
      margin-top: 50px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0;
      .contact_text{
        .p1{
          font-size: 16px;
        }
        .p2{
          font-size: 30px;
          margin: 10px 0;
        }
        .p3{
          display: block;
          width: 250px;
          font-size: 18px;
        }
      }
    }
    .job{
      width: 100%;
      top: 0;
      h1{
        font-size: 25px;
        padding-top: 100px;
      }
      .jobBg{
        width: 280px;
        height: 290px;
        top: 8%;
        z-index: 9;
        margin: 0 auto;
      }
      .jobBox{
        top: 300px;
        div{
          width: 80%;
          margin: 30px auto;
        }
        .job1{
          img{
            width: 42px;
            height: 60px
          }
        }
        .job2{
          img{
            width: 60px;
            height: 53px
          }
        }
        .job3{
          img{
            width: 60px;
            height: 53px
          }
        }
        .job4{
          img{
            width: 38px;
            height: 68px
          }
        }
      }
    }
    .tit{
      margin-top: 80%;
      h2{
        font-size: 24px;
      }
    }
  }
  ::v-deep .el-dialog{
    width: 100%;
  }
}
</style>